.sideItem {
  height: 70px;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  width: 92%;
  /* padding-top: 1rem; */
  border-radius: 10px;
  position: relative;
}

/* .sidebar {
  margin: 0;
  padding: 0;
  width: 300px;
  background-color: black;
  position: fixed;
  height: 100vh;
  overflow: auto;
} */
/* div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
} */
main {
  background: red;
}
.sidebar {
  position: fixed;
  width: 18%;
  height: 100vh;
  background: black;
  font-size: 0.9em;
  overflow: auto;
}

.twitter {
  position: relative;
  float: right;
  min-height: 100vh;
  max-height: auto;
  /* overflow: auto; */
}
