/** card-section **/

.card-section {
  position: relative;
}

.card-block-one .inner-box {
  position: relative;
  display: block;
  padding: 0px 30px 40px 30px;
  z-index: 1;
  margin-bottom: 30px;
}

.card-block-one .inner-box:before {
  position: absolute;
  content: '';
  background: #fff;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: calc(100% - 90px);
  left: 0px;
  bottom: 0px;
  z-index: -1;
}

.card-block-one .inner-box .image-box {
  position: relative;
  display: block;
  border-radius: 10px;
}

.card-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 10px;
}

.card-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding-top: 31px;
}

.card-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 15px;
}

.card-block-one .inner-box .lower-content h3 a {
  display: inline-block;
  color: var(--title-color);
}

.card-block-one .inner-box .lower-content h3 a:hover {
  color: var(--theme-color);
}

.card-block-one .inner-box .lower-content p {
  margin-bottom: 30px;
}

.card-section .pattern-layer .pattern-1 {
  position: absolute;
  top: 250px;
  right: -224px;
  width: 394px;
  height: 394px;
  border-radius: 50%;
  border: 1px solid #e9e9e9;
}

.card-section .pattern-layer .pattern-1:before {
  position: absolute;
  content: '';
  background: #f2f2f2;
  width: 38px;
  height: 38px;
  left: 29px;
  top: 52px;
  border-radius: 50%;
}

.card-section .pattern-layer .pattern-2 {
  position: absolute;
  /* left: -90px; */
  top: 157px;
  width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  -webkit-animation: zoom-fade 8s infinite linear;
  animation: zoom-fade 8s infinite linear;
}

/** RTL **/

/** responsive-css **/

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .card-section {
    padding: 70px 0px 40px 0px;
  }
}

@media only screen and (max-width: 599px) {
}

@media only screen and (max-width: 499px) {
}
