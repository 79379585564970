/** contact-info-section **/

.contact-info-section {
  position: relative;
}

.info-block-one {
  margin-top: 50px;
}

.info-block-one .inner-box {
  position: relative;
  display: block;
  background: rgba(247, 247, 247, 1);
  margin-bottom: 30px;
  padding: 0px 30px 45px 30px;
  border-radius: 20px;
  transition: all 500ms ease;
}

.info-block-one .inner-box:hover {
  transform: translateY(-10px);
}

.info-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 40px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background: var(--theme-color);
  margin-top: -50px;
  margin-bottom: 35px;
}

.info-block-one .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 15px;
}

.info-block-one .inner-box p a {
  display: inline-block;
  color: var(--text-color);
}

.info-block-one .inner-box p a:hover {
  color: var(--theme-color);
}

.google-map-section {
  position: relative;
}

.google-map-section iframe {
  position: relative;
  width: 100%;
  height: 550px;
}

/** contact-section **/

.contact-section {
  position: relative;
  /* background: rgba(247, 247, 247, 1); */
}

.contact-section .form-inner {
  position: relative;
  max-width: 850px;
  width: 100%;
  margin: 0 auto;
}

.contact-section .form-inner .form-group {
  position: relative;
  margin-bottom: 30px;
}

.contact-section .form-inner .form-group:last-child {
  margin-bottom: 0px;
}

.contact-section .form-inner .form-group input[type="text"],
.contact-section .form-inner .form-group input[type="email"],
.contact-section .form-inner .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px solid #fff;
  font-size: 16px;
  color: rgba(103, 103, 103, 1);
  padding: 10px 30px;
  transition: all 500ms ease;
}

.contact-section .form-inner .form-group textarea {
  height: 170px;
  resize: none;
  padding-top: 15px;
}

.contact-section .form-inner .form-group input:focus,
.contact-section .form-inner .form-group textarea:focus {
  border-color: var(--theme-color);
}

/** RTL **/

/** responsive-css **/

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .contact-info-section {
    padding: 70px 0px 40px 0px;
  }

  .google-map-section {
    padding-bottom: 70px;
  }

  .contact-section {
    padding: 70px 0px;
  }
}

@media only screen and (max-width: 599px) {
}

@media only screen and (max-width: 499px) {
}
