@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tin97q');
  src:  url('../fonts/icomoon.eot?tin97q#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tin97q') format('truetype'),
    url('../fonts/icomoon.woff?tin97q') format('woff'),
    url('../fonts/icomoon.svg?tin97q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-1:before {
  content: "\e900";
}
.icon-2:before {
  content: "\e901";
}
.icon-3:before {
  content: "\e902";
}
.icon-4:before {
  content: "\e903";
}
.icon-5:before {
  content: "\e904";
}
.icon-6:before {
  content: "\e905";
}
.icon-7:before {
  content: "\e906";
}
.icon-8:before {
  content: "\e907";
}
.icon-9:before {
  content: "\e908";
}
.icon-10:before {
  content: "\e909";
}
.icon-11:before {
  content: "\e90a";
}
.icon-12:before {
  content: "\e90b";
}
.icon-13:before {
  content: "\e90c";
}
.icon-14:before {
  content: "\e90d";
}
.icon-15:before {
  content: "\e90e";
}
.icon-16:before {
  content: "\e90f";
}
.icon-17:before {
  content: "\e910";
}
.icon-18:before {
  content: "\e911";
}
.icon-19:before {
  content: "\e912";
}
.icon-20:before {
  content: "\e913";
}
.icon-21:before {
  content: "\e914";
}
.icon-22:before {
  content: "\e915";
}
.icon-23:before {
  content: "\e916";
}
.icon-24:before {
  content: "\e917";
}
.icon-25:before {
  content: "\e918";
}
.icon-26:before {
  content: "\e919";
}
.icon-27:before {
  content: "\e91a";
}
.icon-28:before {
  content: "\e91b";
}
.icon-29:before {
  content: "\e91c";
}
.icon-30:before {
  content: "\e91d";
}
.icon-31:before {
  content: "\e91e";
}
.icon-32:before {
  content: "\e91f";
}
.icon-33:before {
  content: "\e920";
}
.icon-34:before {
  content: "\e921";
}
.icon-35:before {
  content: "\e922";
}
.icon-36:before {
  content: "\e923";
}
.icon-37:before {
  content: "\e924";
}
.icon-38:before {
  content: "\e925";
}
.icon-39:before {
  content: "\e926";
}
.icon-40:before {
  content: "\e927";
}
.icon-41:before {
  content: "\e928";
}
.icon-42:before {
  content: "\e929";
}
.icon-43:before {
  content: "\e92a";
}
.icon-44:before {
  content: "\e92b";
}
.icon-45:before {
  content: "\e92c";
}
.icon-46:before {
  content: "\e92d";
}
.icon-47:before {
  content: "\e92e";
}