::-webkit-scrollbar {
  width: 1% !important;
  height: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 1px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7);
  width: 2px;
}
.modal-content {
  background-color: rgb(35, 36, 38) !important;
}
.react-datepicker-wrapper {
  width: -webkit-fill-available;
}
@keyframes autofill {
  0%,
  100% {
    color: #666;
    background: transparent;
  }
}
