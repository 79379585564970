.loader {
  height: 13px;
  aspect-ratio: 5;
  background: radial-gradient(closest-side at 37.5% 50%, #ffffff 94%, #0000) 0 / calc(80% / 3) 100%;
  animation: l47 0.75s infinite;
}
@keyframes l47 {
  100% {
    background-position: 36.36%;
  }
}
