/** team-section **/

.team-section {
  position: relative;
}

.team-block-one .inner-box {
  position: relative;
  display: block;
  margin-bottom: 45px;
}

.team-block-one .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-block-one .inner-box .image-box .image {
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.team-block-one .inner-box .image-box .image img {
  width: 100%;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .image img {
  transform: scale(1.05);
  opacity: 0.6;
}

.team-block-one .inner-box .image-box .social-links {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box .social-links {
  right: 20px;
  opacity: 1;
}

.team-block-one .inner-box .image-box .social-links li {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.team-block-one .inner-box .image-box .social-links li:last-child {
  margin-bottom: 0px;
}

.team-block-one .inner-box .image-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: var(--title-color);
  background: #fff;
  text-align: center;
  border-radius: 50%;
}

.team-block-one .inner-box .image-box .social-links li a:hover {
  color: #fff;
  background: var(--theme-color);
}

.team-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding-top: 20px;
}

.team-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 5px;
}

.team-block-one .inner-box .lower-content h3 a {
  display: inline-block;
  color: var(--title-color);
}

.team-block-one .inner-box .lower-content h3 a:hover {
  color: var(--theme-color);
}

.team-block-one .inner-box .lower-content .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #676767;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .lower-content .designation {
  color: var(--theme-color);
}

/** RTL **/

/** responsive-css **/

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 767px) {
  .team-section {
    padding: 70px 0px 25px 0px;
  }
}

@media only screen and (max-width: 599px) {
}

@media only screen and (max-width: 499px) {
}
